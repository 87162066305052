
/**
 * 휴대폰번호 input 숫자만 입력 처리.
 * @param event
 * @returns {boolean}
 */
function onlyNumber(event){
  event = event || window.event;
  var keyID = (event.which) ? event.which : event.keyCode;
  if ( (keyID >= 48 && keyID <= 57) || (keyID >= 96 && keyID <= 105) || keyID == 8 || keyID == 46 || keyID == 37 || keyID == 39 )
    return;
  else
    return false;
}

/**
 *
 * @param event
 */
function removeChar(event) {
  event = event || window.event;
  var keyID = (event.which) ? event.which : event.keyCode;
  if ( keyID == 8 || keyID == 46 || keyID == 37 || keyID == 39 )
    return;
  else
    event.target.value = event.target.value.replace(/[^0-9]/g, "");
}


/**
 * 폼 체크
 * @returns {boolean}
 */
function frmCheck() {
  var reserveInfo = {};
  reserveInfo.countryCode = $('#frm input[name="nation_cd"]:checked').val();
  reserveInfo.checkAgree = $('#frm input[name="agree"]:checked').val();
  reserveInfo.phoneNumber = $('#frm input[name="tel"]');

  var emailRegExr = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm;
  var TaiwanPhoneRegExr = /((?=(09))[0-9]{10})$/g;

  /**
   * 국가선택 체크
   */

  if (reserveInfo.countryCode === '' || reserveInfo.countryCode === undefined || reserveInfo.countryCode === null) {
    modalFn('CountryCodeError1');
    return false;
  }

  if ( reserveInfo.phoneNumber.val() === '' || reserveInfo.phoneNumber.val() === undefined || reserveInfo.phoneNumber.val() === null) {
    modalFn('TelNumberError1');
    // reserveInfo.phoneNumber.focus();
    return false;
  }

  if (reserveInfo.countryCode === 'TW') {    
    if (TaiwanPhoneRegExr.test(reserveInfo.phoneNumber.val()) === false) {
      modalFn('TelNumberError2');
      // reserveInfo.phoneNumber.focus();
      return false;
    }
  }

  if (reserveInfo.checkAgree !== 'on') {
    modalFn('agreeError');
    return false;
  }

  modalFn('Complete', reserveInfo);
}

/**
 *
 * @param type - String,
 *    팝업 성격 {창닫기: 'Close', 거주국가 미선택: 'CountryCodeError1', 이메일 미입력: 'EmailError1', 이메일 형식오류:
 *     'EmailError2', 이메일및 휴대폰번호 형식오류(Taiwan Only): 'EmailError3', 휴대폰번호 마입력: 'TelNumberError1',
 *     휴대폰번호 형식오류: 'TelNumberError2', 개인정보수집이용및 수신미동의: 'agreeError', 개인정보취급방침 팝업: 'Detail',
 *     사전예약신청완료: 'callSuccess1', 신청된 이메일: 'callSuccess2', 신청된 휴대폰번호: 'callSuccess3', 알 수 없는 오류:
 *     'callError'}
 * @param regInfo - Object,
 *    regInfo.countryCode = $('#reserveForm input[name="country_code"]:checked').val();
 *    regInfo.checkAgree = $('#reserveForm input[name="checkAgree"]:checked').val();
 *    regInfo.email = $('#reserveForm input[name="user_email"]');
 *    regInfo.phoneNumber = $('#reserveForm input[name="user_tel"]');
 */
function modalFn (type, regInfo) {
  var modal = document.getElementById('myModal');
  var modalContainer = modal.getElementsByClassName('modal-body')[0];
  var modalFooter = modal.getElementsByClassName('modal-footer')[0];

  if (type === 'warning') {
    modalContainer.className = 'modal-body';
    modalFooter.innerHTML = '';
  } else {
    // 完成
    modalContainer.className = 'modal-body';
    modalFooter.innerHTML = '<button type="button" class="modal-close" onclick="modalFn(\'Close\');">' +
      '<img src="../src/img/desktop/registration/popup_button_confirm.png" alt="">' +
      '</button>';
  }

  switch (type) {
    case 'Close':
      modal.style.display = "none";
      break;
    // 거주하는 국가 정보를 입력해 주세요 
    case 'CountryCodeError1':
      modalContainer.innerHTML = '<p class="pop_title type2">請選擇居住地區</p>';
      modal.style.display = "block";
      break;
    // 전화번호를 입력해주세요 (-는 포함하지않습니다)  
    case 'TelNumberError1':
      modalContainer.innerHTML = '<p class="pop_title type2">請輸入手機號碼（不包含-）</p>';
      modal.style.display = "block";
      break;
    // 본인의 전화번호를 정확하게 입력해주세요
    case 'TelNumberError2':
      modalContainer.innerHTML = '<p class="pop_title type2">請輸入正確的手機號碼</p>';
      modal.style.display = "block";
      break;
    // 개인정보 수집·이용 및  SMS 수신에 동의해 주세요.
    case 'agreeError':
      modalContainer.innerHTML = '<p class="pop_title type2">請授權蒐集使用個人資料 : SMS</p>';
      modal.style.display = "block";
      break;
    // 개인정보 이용동의 전문  
    case 'Detail':
      modalContainer.innerHTML = '<p class="pop_title">關於同意個人資料的蒐集使用</p>' +
        '<div class="pop_desc">' +
        '<ul style="text-align:left;">' +
        '<li>[本公司將蒐集的個人資料]<br /> - 手機號碼</li>' +
        '<li>[蒐集個人資料目的]<br /> - Brown Dust 改版更新提醒以及提供各種Neowiz的遊戲最新消息</li>' +
        '<li>[個人資料使用期間]<br /> - 自用戶同意蒐集個人資料開始起算，持續10個月</li>' +
        '<li> - 如果對於個人資料的蒐集以及利用有疑慮， 用戶可以拒絕提供相關個人資料，<br /> 但恕無法參與此次活動</li>' +
        '<li>[個人資料委託]<br /> - 手機號碼的使用目的為SMS簡訊發送，屆時個人資料中的手機號碼將轉交給<br /> “智冠科技股份有限公司”進行簡訊發送，使用目的達成後，智冠科技將立即清除相關資料</li>' +
        '<li>※ 輸入的手機號碼資料，到使用期間到期為止，本公司將發送遊戲相關多樣優惠最新消息（新款手機遊戲，活動最新訊息），拒絕提供個人資料用戶將無法取得優惠。</li>' +
        '<li>※ 如任意輸入他人的手機號碼的行為， 將會受到相關法律處罰處理，請務必輸入正確的個人資料。' +'<br>'+
        '如有錯誤及任意輸入錯誤資料的相關責任將歸於輸入本人身上。</li>' +
        '</ul>' +
        '</div>';
      modal.style.display = "block";
      break;
    case 'callSuccess1':
      modalContainer.innerHTML = '<p class="pop_title type2">預約登錄完成！</p>';
      modal.style.display = "block";
      break;
     // 귀하의 휴대 전화 번호가 등록되어 있습니다.
    case 'callSuccess2':
      modalContainer.innerHTML = '<p class="pop_title">您的手機號碼已參加預約登錄</p>';
      modal.style.display = "block";
      break;
     // 통신 실패. 다시 시도해주세요.
    case 'callError':
      modalContainer.innerHTML = '<p class="pop_title">預約登錄申請失敗，</p><p class="pop_desc">請再重新試試！</p>';
      modal.style.display = "block";
      break;    
    case 'warning':
      modalContainer.innerHTML = '<p class="pop_title text-left">注意事項</p><p class="pop_desc text-left">' +
        '   登入遊戲後即可確認.<br>' +
        '. 商店事前預約獎勵,每個遊戲帳號只能領取1 次活動獎勵.</p>';
      modal.style.display = "block";
      break;
    case 'Complete':
      var data = $('form').serialize();
      // $.post('/event/request/join', data, function(ret){
      //   if(typeof ret === 'object'){
      //     if(ret.result === 'ok'){
      //       modalFn('callSuccess1');
            
      //     }else{
      //       fbq('track', 'ErrorRegistration');
      //       if (ret.msg === "mobile") {
      //         modalFn('callSuccess2');
      //         $('#reserveForm input[name="email"]').val('');
      //         $('#reserveForm input[name="tel"]').val('');
      //       }
      //       else {
      //         modalContainer.innerHTML = '<p class="pop_title">'+ret.msg+'</p><p class="pop_desc"></p>';
      //         modal.style.display = "block";
      //         return;
      //       }
      //     }
      //   }else{
      //     modalFn('callError');
      //     return ;
      //   }
      // });
      modalFn('callSuccess1');
      break;
    default:
      modalFn('callError');
      break;
  }
}
